import React, {
  useState,
  useEffect,
  useContext,
  // useMemo,
} from 'react';
// import { Switch } from 'antd';
import { Viewer } from '@react-pdf-viewer/core';
import { downloadButton } from 'components/utils/constants/buttons';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload/interface';
// import { getFileList } from 'components/EditMemberProfile/utils';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { identity } from 'utils';
import { Input, RenderIf, Modal } from 'components/components';
import { InsuranceColumnsProps } from 'types/services/requestService';
import { formatSlashMDY } from 'components/utils/constants/formatters';
import DataColumn from 'components/components/DataColumns/components/DataColumn';
import DependantForm from 'components/RequestServiceDrawer/components/DependantForm';
import TeamMemberDetails from 'components/RequestServiceDrawer/components/TeamMemberDetails';
import { planTypeOptions } from 'components/RequestServiceDrawer/utils/constants';

import '../../../../style.css'
import { AgreementField } from 'components/RequestsOverview/components/RequestDetailsDrawer/components/OverviewTab/components';
import { MyContext } from 'components/RequestServiceDrawer/View';
import { postServiceRequestDocuments } from 'packages/service_request_repository/api';

type DataPayload<DataType> = {
  data: DataType
}

const InsuranceColumns = (props: InsuranceColumnsProps) => {
  const {
    member,
    state,
    plan,
    getMemberById,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setIsUserUAERes,
    // setIsApplyingForDependant,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setPlan,
    setTeamMember,
    setRequestDocuments,
    setETAWithSignFile,
    setQuantity,
    setEmiratesIDNo,
    setPassportNo,
  } = props;

  const {
    passportNumber,
    setPassportNumber,
    setIsAgreeCondition,
  } = useContext(MyContext)!

  const {
    isApplyingForDependant, teamMember, displayDocuments,
  } = state.request;
  const [teamMemberId, setTeamMemberId] = useState(0);
  const [open, setOpen] = useState(false);
  const [isDisabledCheckbox, setIsDisabledCheckbox] = useState(true);
  const AgreementFileUrl = 'https://rc1-be.hub71.app/static/docs/adnic-disclaimer.pdf';

  const handleOpenTermsConditions = () => {
    setOpen(true)
    setIsDisabledCheckbox(false)
  }
  const [activeEmiratesID, setActiveEmiratesID] = useState<boolean>(false)

  const handleEmiratesIDNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data: string = e.target.value;
    const val = { data } as DataPayload<string>
    setEmiratesIDNo(val);
  };

  const handlePassportNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data: string = e.target.value;
    setPassportNumber(data)
    const val = { data } as DataPayload<string>
    setPassportNo(val);
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setIsAgreeCondition(e.target.checked);
  };

  useEffect(() => {
    setTeamMember({ data: member })
  }, [member]);

  useEffect(() => {
    if (identity.isTruthyNumber(teamMemberId)) {
      getMemberById({ id: teamMemberId })
    } else {
      setTeamMember({ data: undefined });
    }
  }, [teamMemberId]);

  useEffect(() => {
    setQuantity({ data: 1 });
  }, []);

  // const defaultFileList: UploadFile[] = useMemo(() => getFileList(member.visa as string), [member.visa]);

  const handleBeforeUpload = async (file: RcFile) => {
    setVisaFile({ data: file as Blob });
    const res = await postServiceRequestDocuments({
      _document: file,
      _name: 'Visa Document',
      _service_request_id: 0,
    })
    if (res.httpStatus === 200 && res.data?.status === 'ok') {
      window.localStorage.setItem('visa-doc', JSON.stringify(res.data))
    }
    return false;
  }

  return (
    <div className="overview-columns">
      <RenderIf condition={!displayDocuments}>
        <DataColumn label="Choose team member" className="input" required>
          <Input.Select
            options={props.teamMembersOptions}
            value={teamMember?.id || 0}
            onChange={(data: number) => setTeamMemberId(data)}
          />
        </DataColumn>
      </RenderIf>
      <RenderIf condition={identity.isTruthyNumber(teamMember?.id)}>
        <TeamMemberDetails
          header="Date of birth"
          required
          subheader={identity.isTruthyString(teamMember?.dateOfBirth) ? moment(teamMember?.dateOfBirth).format(formatSlashMDY) : 'Not specified'}
        />
        <RenderIf condition={!teamMember?.dateOfBirth}>
          <TeamMemberDetails
            header="Date of Birth"
            subheader="Please set the Date of birth from the profile page"
            isError
            required
          />
        </RenderIf>
        <RenderIf condition={identity.isTruthyString(teamMember?.gender) && teamMember?.gender !== 'Not Specified'}>
          <TeamMemberDetails header="Gender" subheader={teamMember?.gender} required />
        </RenderIf>
        <RenderIf condition={!teamMember?.gender || teamMember?.gender === 'Not Specified'}>
          <TeamMemberDetails header="Gender" subheader="Please set Gender from the profile page" isError required />
        </RenderIf>
        <RenderIf condition={identity.isTruthyString(teamMember?.nationality)}>
          <TeamMemberDetails header="Nationality" subheader={teamMember?.nationality} required />
        </RenderIf>
        <RenderIf condition={!teamMember?.nationality}>
          <TeamMemberDetails
            header="Nationality"
            subheader="Please set the Nationality from profile page"
            isError
            required
          />
        </RenderIf>
        <RenderIf condition={identity.isTruthyString(teamMember?.phoneNumber)}>
          <TeamMemberDetails header="Mobile Number" subheader={teamMember?.phoneNumber} required />
        </RenderIf>
        <RenderIf condition={!teamMember?.phoneNumber}>
          <TeamMemberDetails
            header="Mobile Number"
            subheader="Please set the Mobile number from the profile page"
            isError
            required
          />
        </RenderIf>
        <Input label="Passport Number" name="Passport Number" layout="vertical" rules={[]} required>
          <Input.InputField type="text" onChange={handlePassportNo} placeholder={`${!passportNumber ? 'Please set the Passport Number from the profile page' : 'Please enter passport number'}`} />
        </Input>
        <RenderIf condition={identity.isTruthyString(teamMember?.passport)}>
          <DataColumn label="Passport File" required>
            <AgreementField fileUrl={teamMember?.passport as string} fileName="Passport.pdf" altText="passport file" />
          </DataColumn>
        </RenderIf>
        <RenderIf condition={!teamMember?.passport}>
          <TeamMemberDetails header="Passport File" subheader="Please upload Passport File from the profile page" isError />
        </RenderIf>
        <DataColumn label="Do you have Emirates ID?" className="input">
          <Input.Switch checked={activeEmiratesID} onChange={(data) => setActiveEmiratesID(data)} />
        </DataColumn>
        <RenderIf condition={activeEmiratesID}>
          <Input label="Emirates ID" layout="vertical" rules={[]}>
            <Input.InputField type="text" name="Emirates ID" onChange={handleEmiratesIDNo} placeholder="Please enter emirates ID number" />
          </Input>
        </RenderIf>
        <RenderIf condition={identity.isTruthyString(teamMember?.emiratesId) && activeEmiratesID}>
          <DataColumn label="Emirates ID File">
            <AgreementField fileUrl={teamMember?.emiratesId as string} fileName="Emirate_ID.pdf" altText="emirates id file" />
          </DataColumn>
        </RenderIf>
        <RenderIf condition={identity.isTruthyNumber(teamMemberId)}>
          <Input label="Visa Document" required>
            <Input.DraggerUpload
              accept=".pdf"
              // defaultFileList={member.visa ? defaultFileList : []}
              beforeUpload={handleBeforeUpload}
              allowChange
              isRoundableButton
              previewType="image"
            />
          </Input>
        </RenderIf>
      </RenderIf>
      {/* <RenderIf condition={!displayDocuments && identity.isTruthyNumber(teamMemberId)}>
        <DataColumn label="" className="input">
          <div className="switch-wrap">
            <div className="request-service-details-description">
              <p>Applying for the dependant?</p>
            </div>
            <Switch
              size="default"
              onChange={(e) => setIsApplyingForDependant({ data: e })}
              className="switcher"
            />
          </div>
        </DataColumn>
      </RenderIf> */}
      <RenderIf condition={isApplyingForDependant!}>
        <DependantForm
          setRelationToTM={setRelationToTM}
          setIsDependantUAE={setIsDependantUAE}
          setDependantGender={setDependantGender}
          setDependantFullName={setDependantFullName}
          setDependantDateOfBirth={setDependantDateOfBirth}
          setChildPlaceOfBirth={setChildPlaceOfBirth}
          setIsUserUAERes={setIsUserUAERes}
          setPassportFile={setPassportFile}
          setVisaFile={setVisaFile}
          setVisaProofFile={setVisaProofFile}
          setEmiratesIDFile={setEmiratesIDFile}
          setRequestDocuments={setRequestDocuments}
          setETAWithSignFile={setETAWithSignFile}
          state={state}
        />
      </RenderIf>
      <RenderIf
        condition={
          !displayDocuments && identity.isTruthyString(passportNumber) && identity.isTruthyNumber(teamMemberId)
        }
      >
        <DataColumn label="Choose plan" className="input" required>
          <Input.Select
            value={plan}
            placeholder="Enter or choose from dropdown"
            className="quantity-select"
            onChange={(data) => setPlan({ data })}
            options={planTypeOptions}
          />
        </DataColumn>
      </RenderIf>
      <RenderIf condition={identity.isTruthyNumber(teamMember?.id)}>
        <div style={{ display: 'flex' }}>
          <Checkbox disabled={isDisabledCheckbox} onChange={onChangeCheckbox}>{' '}</Checkbox>
          <p>
            Please read
            {' '}
            <span className="terms-conditions" onClick={handleOpenTermsConditions}>ADNIC Disclaimer</span>
          </p>
          <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            title="Agreement"
            buttons={downloadButton(AgreementFileUrl as string, 'pdf')}
          >
            <div className="al-pdf-preview__container">
              <Viewer fileUrl={AgreementFileUrl!} />
            </div>
          </Modal>
        </div>
      </RenderIf>
    </div>
  );
}

export default InsuranceColumns;
