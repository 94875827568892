import React, { useState } from 'react';
import { Button } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { formatDateDashYMDTimeHMS } from 'components/utils/constants/formatters';
import { UploadInvoiceFieldsProps } from 'types/requests';
import { Input } from 'components/components';

import './UploadInvoiceFields.scss';

const UploadInvoiceFields = (props: UploadInvoiceFieldsProps) => {
  const { serviceRequest, actions: { setShowInvoiceFields, updateServiceRequest } } = props;
  const [invoiceFile, setInvoiceFile] = useState<string | Blob>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [newInvoicePrice, setNewInvoicePrice] = useState<number>(serviceRequest.totalPrice);

  const beforeInvoiceUpload = (file: RcFile) => {
    setInvoiceFile(file as Blob);
    return false;
  }

  const handleSubmit = () => {
    updateServiceRequest({
      id: serviceRequest.id,
      invoice: invoiceFile,
      invoiceNumber,
      totalPrice: newInvoicePrice,
      invoiceDate: moment(invoiceDate).format(formatDateDashYMDTimeHMS),
    });
  }

  return (
    <div className="upload-invoice-fields">
      <Input label="Invoice number" required>
        <Input.InputField
          placeholder="Enter Invoice Number"
          name="invoiceNumber"
          value={invoiceNumber}
          onChange={({ target: { value } }) => setInvoiceNumber(value)}
        />
      </Input>
      <Input label="Invoice Date" required>
        <Input.DatePicker
          placeholder="Enter Invoice Date"
          name="invoiceDate"
          value={invoiceDate}
          onChange={(value) => setInvoiceDate(value)}
        />
      </Input>
      <Input label="Total price" required>
        <Input.InputField
          placeholder="Total Price Excluding VAT"
          name="totalPrice"
          value={newInvoicePrice}
          onChange={(e: any) => setNewInvoicePrice(e.target.value)}
        />
      </Input>
      {newInvoicePrice > serviceRequest.totalPrice && (
        <div style={{ textAlign: 'center' }}>
          <p style={{ color: 'red' }}>{`Price cannot be more than ${serviceRequest.totalPrice}`}</p>
        </div>
      )}
      <Input label="Invoice pdf" required>
        <Input.DraggerUpload
          beforeUpload={beforeInvoiceUpload}
          previewType="image"
          name="invoice"
          accept=".pdf"
          isRoundableButton
        />
      </Input>
      <div className="upload-invoice-fields__buttons">
        <Button className="secondary-button md" onClick={() => setShowInvoiceFields({ data: false })}>Cancel</Button>
        <Button
          className="primary-button md"
          onClick={handleSubmit}
          disabled={
            invoiceNumber === ''
            || invoiceFile === ''
            || !newInvoicePrice
            || newInvoicePrice > serviceRequest.totalPrice
            || !invoiceDate
          }
        >
          Deliver
        </Button>
      </div>
    </div>
  );
}

export default UploadInvoiceFields;
